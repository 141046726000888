export default [
  { link: '/for-servers', title: 'For Process Servers' },
  { link: '/pricing', title: 'Pricing' },
  {
    link: '#',
    title: 'Products',
    subTitle: 'The Perfect Legal Platform',
    alignment: 'vertical',
    subMaxWidth: '300px',
    subItems: [
      { link: '/how-it-works', title: 'Service of Process', icon: 'nav-products-documents.svg' },
      { link: '/site-visits', title: 'Site Visits', icon: 'nav-products-check-file.svg' }
    ]
  },
  {
    link: '/for-law-firms',
    title: 'Problems We Solve',
    subTitle: 'The Perfect Legal Platform',
    subItems: [
      { link: '/for-law-firms', title: 'For Law Firms', icon: 'nav-problems-law.svg' },
      { link: '/for-collections-agencies', title: 'For Collections', icon: 'nav-problems-server.svg' },
      { link: '/for-individuals', title: 'For Individuals', icon: 'nav-problems-individual.svg' },
      { link: '/for-government', title: 'For Government', icon: 'nav-problems-government.svg' },
      { link: '/for-process-serving-companies', title: 'For Companies', icon: 'nav-problems-office-building.svg' },
      { link: '/for-law-enforcement', title: 'For Law Enforcement', icon: 'nav-problems-law-enforcement.svg' },
      { link: '/property-management', title: 'Property Management', icon: 'nav-problems-home.svg' }
    ]
  },
  {
    link: '/learn',
    title: 'Resources',
    subTitle: 'Resources',
    multilevelSubItems: [
      {
        heading: 'Resources',
        items: [
          { link: '/learn', title: 'Blog', icon: 'nav-resources-blog.svg' },
          { link: '/events', title: 'Events', icon: 'nav-resources-calendar.svg' },
          { link: '/about#press', title: 'Press', icon: 'nav-resources-press.svg' },
          { link: 'https://developer.proofserve.com/#introduction', title: 'API Docs', icon: 'nav-resources-api.svg' },

          { link: '/service-of-process-rules', title: 'Service of Process Rules', icon: 'nav-resources-check.svg' },
          { link: '/service-areas', title: 'Service Areas', icon: 'nav-resources-pin.svg' },
          { link: '/success-stories', title: 'Success Stories', icon: 'nav-problems-individual.svg' },
          { link: '/integrations', title: 'Integrations', icon: 'nav-resources-integrations.svg' },
          { link: '/associations', title: 'Associations', icon: 'nav-resources-associations.svg' }
        ]
      },
      {
        heading: 'Value Calculator',
        items: [
          { link: '/calculator/firm', title: 'Firm', icon: 'nav-resources-building.svg' },
          { link: '/calculator/property-management', title: 'Property Management', icon: 'nav-resources-property-manage.svg' },
          { link: '/calculator/collection', title: 'Collections', icon: 'nav-resources-collections.svg' }
        ]
      },
      {
        heading: 'Apps',
        items: [
          { link: '/integrations/filevine', title: 'FileVine', icon: 'nav-resources-filevine.svg' },
          { link: '/integrations/clio', title: 'Clio', icon: 'nav-resources-clio2.svg' },
          { link: '/integrations/mycase', title: 'MyCase', icon: 'nav-resources-mycase.svg' }
        ]
      }
    ]
  }
];
